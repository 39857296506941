import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Footer } from './Footer';
import { Row, Col, Menu } from 'antd';
import { MailOutlined, PhoneOutlined, MessageOutlined, HomeOutlined, FolderOpenOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import '../stylesheets/App.scss';

const { SubMenu } = Menu;

export default class Contact extends Component {

    goToProjects = () => {
        this.props.history.push(`/projets`);
    }

    onMouseEnter = () => {
        alert("Ce formulaire n'est pas encore fonctionnel, mais le sera bientôt. Veuillez s'il vous plaît me contacter pour le moment à l'adresse mail indiquée ci-dessous.")
    }

    render() {
        return (
            <div className="App">

                <div className="App-header">
                    <div className="App-container">
                        <a
                            className="App-link"
                            href="/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <img src={logo} className="App-logo" alt="logo" /> */}
                            <span className="App-logo">&lt;lH/&gt;</span>
                        </a>

                        <Menu mode="horizontal">
                            <Menu.Item key="home" icon={<HomeOutlined />}>
                                <Link to="/">
                                    Accueil
                                </Link>
                            </Menu.Item>
                            <SubMenu key="SubMenu" icon={<FolderOpenOutlined />} title="Projets">
                                <Menu.Item onClick={() => this.goToProjects()} key="work1">Projet 1</Menu.Item>
                                <Menu.Item key="work2">Projet 2</Menu.Item>
                                <Menu.Item key="work3">Projet 3</Menu.Item>
                            </SubMenu>
                            <Menu.Item key="contact" icon={<MessageOutlined />}>
                                <Link to={`/contact`}>
                                    Contact
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>

                <div className="contact">
                    <div className="App-container">
                        <h1>Contact</h1>
                        <h2>Je réponds à toutes vos questions.</h2>
                        <Row className="mail-phone">
                            <Col xs={24} md={8}>
                                <a href="mailto:laura.henocq@gmail.com"><MailOutlined /> laura.henocq@gmail.com</a>
                            </Col>
                            <Col xs={24} md={8}>
                                <p><PhoneOutlined /> 06.12.87.32.54</p>
                            </Col>
                        </Row>
                        <form method="post" action="fichiertraitementdonnees.php" className="Form-container" onMouseEnter={()=>this.onMouseEnter()} onClick={()=>this.onMouseEnter()} >
                            <Row>
                                <Col className="form" xl={12} sm={12} md={12} xs={24}>
                                    <Row>
                                        {/* <Col className="formLabel">
                                            <label className="contactForm" htmlFor="firstname">Votre nom</label>
                                        </Col> */}
                                        
                                            <input id="firstname" name="firstname" type="text" required placeholder="Votre nom" />
                                        
                                    </Row>
                                    <Row>
                                        {/* <Col className="formLabel">
                                            <label className="contactForm" htmlFor="company">Société</label>
                                        </Col> */}
                                        
                                            <input id="company" name="company" type="text" required placeholder="Société" />
                                        
                                    </Row>
                                    <Row>
                                        {/* <Col className="formLabel">
                                            <label className="contactForm" htmlFor="email">Votre email</label>
                                        </Col> */}
                                        
                                            <input id="email" name="email" type="text" required placeholder="Votre mail" />
                                        
                                    </Row>
                                    <Row>
                                        {/* <Col className="formLabel">
                                            <label className="contactForm" htmlFor="subject">Votre sujet</label>
                                        </Col> */}
                                        
                                            <input id="subject" name="subject" type="text" required placeholder="Votre sujet" />
                                        
                                    </Row>
                                </Col>

                                <Col className="form" xl={12} sm={12} md={12} xs={24}>
                                    <textarea id="textareaMessage" name="message" rows="6" defaultValue="Votre message."></textarea>
                                    <input className="btn" id="submit" type="submit" value="Envoyer" />
                                </Col>
                            </Row>
                        </form>

                    </div>

                </div>

                <Footer />

            </div>
        )
    }
}


import React from 'react'
import { GithubOutlined, LinkedinOutlined } from '@ant-design/icons';

export const Footer = () => {
    return (
        <div className="App-footer">

            <div className="App-container">

                <div className="copyright">
                    <span>Copyright 2020.</span>
                </div>

                <div className="link">

                    <div className="media-btn">

                        <a className="icones" href="https://github.com/LauraHenocq" target="_blank" rel="noopener noreferrer">
                            <GithubOutlined />
                        </a>

                    </div>

                    <div className="media-btn">

                        <a className="icones" href="https://fr.linkedin.com/in/laura-h%C3%A9nocq-49a9831b5" target="_blank" rel="noopener noreferrer">
                            <LinkedinOutlined />
                        </a>

                    </div>

                    <div className="media-btn">

                        <a className="icones" href={`${process.env.PUBLIC_URL}/docs/CV_2020.pdf`} target="_blank" rel="noopener noreferrer">
                            <span>CV</span>
                        </a>

                    </div>

                </div>

            </div>

        </div>
    )
}

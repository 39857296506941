import React from 'react';
import ReactDOM from 'react-dom';
//import de la feuille de style de Ant Design
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import NotFound from './components/NotFound';
import Contact from './components/Contact';
import Projects from './components/Projects';
import reportWebVitals from './reportWebVitals';

// Import des composants nécessaires à la mise en place de notre système de routes/navigation de notre application
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

export const Root = () => {
  return(
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/contact" component={Contact}/>
        <Route exact path="/projets" component={Projects}/>
        {/* <Route path="/projets/:id" component={Project}/> */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

ReactDOM.render(
    <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FolderOpenOutlined, HomeOutlined, MessageOutlined, DownOutlined } from '@ant-design/icons';
// import logo from './logo.png';
import { Menu } from 'antd';
import 'antd/dist/antd.css';
import './stylesheets/App.scss';

import { AboutMe } from './components/AboutMe';
import { Footer } from './components/Footer';

import about from './about';
import illustration from './img/illustration.png';

const { SubMenu } = Menu;


export default class App extends Component {

  state = {
    about,
    illustration,
    current: 'home',
    modalVisible: false
  }

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };

  goToProjects = () => {
    this.props.history.push(`/projets`);
  }

  moreAboutMe = () => {
    this.setState({
      modalVisible: true,
    });
  }

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  }

  render() {
    const { current } = this.state.current;
    return (
      <div className="App">
  
        <div className="App-header">
          <div className="App-container">
            <a
              className="App-link"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <img src={logo} className="App-logo" alt="logo" /> */}
              <span className="App-logo">&lt;lH/&gt;</span>
            </a>

            <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
              <Menu.Item key="home" icon={<HomeOutlined />}>
                <Link to="/">
                  Accueil
                </Link>
              </Menu.Item>
              <SubMenu key="SubMenu" icon={<FolderOpenOutlined />} title="Projets">
                <Menu.Item onClick={() => this.goToProjects()} key="work1">Projet 1</Menu.Item>
                <Menu.Item key="work2">Projet 2</Menu.Item>
                <Menu.Item key="work3">Projet 3</Menu.Item>
              </SubMenu>
              {/* <Menu.Item key="works">
                <a href="/projets" target="_blank" rel="noopener noreferrer">
                  Projets
                </a>
              </Menu.Item> */}
              <Menu.Item key="contact" icon={<MessageOutlined />}>
                <Link to={`/contact`}>
                  Contact
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </div>
        
        <div className="banniere">
          <div className="App-container">
            <h1>Laura Hénocq</h1>
            <h2>Développeuse Web/Web mobile</h2>

            <a href="#aboutMe">
              <DownOutlined />
            </a>

          </div>

        </div>

        <AboutMe
          about={this.state.about}
          illustration={illustration}
          modalVisible={this.state.modalVisible}
          moreAboutMe={() => this.moreAboutMe()}
          handleCancel={() => this.handleCancel()}
          goToProjects={() => this.goToProjects()}
        />

        < Footer />

      </div>
    )
  }
}

import React, { Component } from 'react';
import { Menu, Carousel, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Footer } from './Footer';
import { MessageOutlined, HomeOutlined, FolderOpenOutlined, ToolOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import '../stylesheets/App.scss';
import workinprogress from '../img/workinprogress.jpg';
import brunofourquetwebsite from '../img/brunofourquetwebsite.png';

const { SubMenu } = Menu;

const RightArrow = () => {
    return (
        <Button 
            style={{padding: "1rem", height: "auto", width: "auto", color:"tomato"}}
            icon={<RightCircleOutlined style={{fontSize: 100}}/>} size="middle"
        />
    )
}

const LeftArrow = () => {
    return (
        <Button icon={<LeftCircleOutlined/>} size="middle"/>
    )
}

const carouselStyle = {
    height: 'auto',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    fontFamily: 'Playfair Display',
    fontSize: '2rem'
  };

const carouselContentStyle = {
    color: '#fff',
    lineHeight: '7rem',
    textAlign: 'center',
    background: '#8db596',
    fontFamily: 'Playfair Display',
    fontSize: '2rem',
}

const ImageStyle = {
    width:'auto',
    margin:'auto',
}

export default class Projects extends Component {

    goToProjects = () => {
        this.props.history.push(`/projets`);
    }

    goToProject = (id) => {
        this.props.history.push(`/projets/${id}`);
    }

    render() {
        return (
            <div className="App">

                <div className="App-header">
                    <div className="App-container">
                        <a
                            className="App-link"
                            href="/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <img src={logo} className="App-logo" alt="logo" /> */}
                            <span className="App-logo">&lt;lH/&gt;</span>
                        </a>

                        <Menu mode="horizontal">
                            <Menu.Item key="home" icon={<HomeOutlined />}>
                                <Link to="/">
                                    Accueil
                                </Link>
                            </Menu.Item>
                            <SubMenu key="SubMenu" icon={<FolderOpenOutlined />} title="Projets">
                                <Menu.Item onClick={() => this.goToProjects()} key="work1">Projet 1</Menu.Item>
                                <Menu.Item key="work2">Projet 2</Menu.Item>
                                <Menu.Item key="work3">Projet 3</Menu.Item>
                            </SubMenu>
                            <Menu.Item key="contact" icon={<MessageOutlined />}>
                                <Link to={`/contact`}>
                                    Contact
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>

                <div className="projects">
                    <div className="App-container">
                        <h1>Cette page est en cours de construction <ToolOutlined spin /></h1>
                        <h2>Merci de renouveller votre visite ultérieurement.</h2>

                        <Carousel 
                            autoplay 
                            effect="fade" 
                            style={carouselStyle} 
                            pauseOnDotsHover={true} 
                            pauseOnFocus={true} 
                            //arrows={true} 
                            //prevArrow={LeftArrow()} 
                            //nextArrow={RightArrow()}
                        >
                            <div onClick={() => this.goToProject(1)}>
                                <h3 style={carouselContentStyle}>Projet 1 - Site Web pour Bruno Fourquet, graphiste, illustrateur et directeur artistique.</h3>
                                <img src={brunofourquetwebsite} alt="Site Web de Bruno Fourquet" style={ImageStyle}/>
                            </div>
                            <div onClick={() => this.goToProject(2)}>
                                <h3 style={carouselContentStyle}>Projet 2 - Titre du projet</h3>
                                <img src={workinprogress} alt="WorkInProgress" style={ImageStyle}/>
                            </div>
                            <div onClick={() => this.goToProject(3)}>
                                <h3 style={carouselContentStyle}>Projet 3 - Titre du projet</h3>
                                <img src={workinprogress} alt="WorkInProgress" style={ImageStyle}/>
                            </div>
                            <div onClick={() => this.goToProject(4)}>
                                <h3 style={carouselContentStyle}>Projet 4 - Titre du projet</h3>
                                <img src={workinprogress} alt="WorkInProgress" style={ImageStyle}/>
                            </div>
                        </Carousel>

                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}


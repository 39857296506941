import React from 'react'
import {Row, Col, Card, Button, Modal } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';

export const AboutMe = props => {
    return (
        <div className="about" id="aboutMe">
          <div className="App-container">
              <Row>
                <Col xl={12}>
                  <h1>{props.about.title}</h1>
                  <Row>
                    <p>{props.about.description} <span className="moreAboutMe" onClick={props.moreAboutMe}> En lire plus à propos de moi <PlusSquareOutlined /> </span> </p>
                  </Row>
                  <Modal 
                    title="Mon parcours"
                    visible={props.modalVisible}
                    onCancel={props.handleCancel}
                    onOk={props.handleCancel}
                    centered
                    width={"80%"}
                    bodyStyle={{
                      height:"auto",
                      padding : 20,
                      overflow:'auto',
                      backgroundColor:"#bedbbb",
                      color:":#707070",
                      fontFamily:"Roboto"
                    }}
                    footer={[
                      <Button key="ok" type="primary" onClick={props.handleCancel}>OK</Button>
                    ]}

                  >
                    <p className="fullDescriptionText">{props.about.FullDescription_p1}</p>
                    <p className="fullDescriptionText">{props.about.FullDescription_p2}</p>
                    <p className="fullDescriptionText">{props.about.FullDescription_p3}</p>
                  </Modal>
                  
                  <Button
                    className="btn"
                    onClick={props.goToProjects}
                  >
                    Découvrez mes projets
                  </Button>
                </Col>
                <Col xl={12}>
                  <Card
                    cover={
                      <img
                        alt="illustration"
                        src={props.illustration}
                      />
                    }
                  />
                </Col>
              </Row>
          </div>
        </div>
    )
}
